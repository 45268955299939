import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    Divider,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Checkbox
} from '@mui/material';

const ShowGroupPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ accountData, setAccountData ] = useState( null );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    useEffect( () => {
        const fetchGroup = async () => {
            try {
                const response = await fetch( `${getApiUrl()}/api/groups/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } );

                const data = await response.json();

                if ( response.ok ) {
                    setAccountData( data.data );
                    setLoading( false );
                }
                else
                {
                    throw new Error( data.error ?? JSON.stringify( data ) );
                }
            }
            catch ( err ) {
                setError( `${t( 'show_group_failed' )}: ${err.message}` );
                setLoading( false );
                setIsErrorModalOpen( true );
            }
        };

        fetchGroup();
    }, [ id ] );

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( '/groups' );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    if ( isErrorModalOpen )
    {
        return (
            <>
                <Dialog
                    open={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('error')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {error ? error : t('unknown_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseErrorModal}
                            variant="contained"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <Paper sx={{ p: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('group_details')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('id')}
                            </Typography>
                            <TextField
                                value={accountData.id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_id')}
                            </Typography>
                            <TextField
                                value={accountData.account_id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('guid')}
                            </Typography>
                            <TextField
                                value={accountData.guid}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('members_count')}
                            </Typography>
                            <TextField
                                value={accountData.members_count}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('title')}
                            </Typography>
                            <TextField
                                value={accountData.title}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('slow_mode')}
                            </Typography>
                            <TextField
                                value={accountData.slow_mode === 0 ? t('disabled') : accountData.slow_mode + "s"}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('chat_history_for_new_members')}
                            </Typography>
                            <TextField
                                value={accountData.chat_history_for_new_members === 'Visible' ? t('visible') : t('hidden')}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('event_messages')}
                            </Typography>
                            <TextField
                                value={accountData.event_messages ? t('visible') : t('hidden')}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('description')}
                            </Typography>
                            <TextField
                                value={accountData.description}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& textarea': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('accesses')}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                {["ViewMembers", "ViewAdmins", "SendMessages", "AddMember"].map((accessOption, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            checked={
                                                accountData.accesses &&
                                                (typeof accountData.accesses === 'string'
                                                    ? JSON.parse(accountData.accesses)
                                                    : accountData.accesses).includes(accessOption)
                                            }
                                            disabled
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: 'gray'
                                                },
                                            }}
                                        />
                                        <Typography variant="body2">
                                            {t(accessOption)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/')}
                            fullWidth
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {t('return_home')}
                        </Button>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
};

export default ShowGroupPage;
