import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Paper,
    Typography,
    Grid,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Checkbox,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Pagination,
    CircularProgress,
    Box,
    MenuItem,
    Select,
    FormControl,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider
}
    from '@mui/material';

const StoreBatchGroupsPage = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const { t, i18n } = useTranslation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [isAccountsModalOpen, setIsAccountsModalOpen] = useState(false);

    // Combined form state
    const [formData, setFormData] = useState({
        accounts_selection_type: 'all',
        selected_accounts: [],
        groups_count: 1,
        contacts_count: 1,
        title: '',
        description: '',
        slow_mode: 0,
        chat_history_for_new_members: 'Visible',
        event_messages: 0,
        chat_reaction_setting: 'Disabled',
        accesses: ['ViewMembers'],
        message: {
            text: '',
        }
    });

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const fetchAccounts = async (currentPage = 1) => {
        setLoading(true);
        try {
            const response = await axios.get(`${getApiUrl()}/api/accounts`, {
                params: { page: currentPage },
                headers: { 'Content-Type': 'application/json' },
            });
            setAccounts(response.data.data);
            setTotalPages(response.data.summary.total_pages);
            setLoading(false);
        }
        catch (err) {
            setError(err.message);
        }
        finally {
            setLoading(false);
        }
    };

    const openAccountsModal = () => {
        setIsAccountsModalOpen(true);
        fetchAccounts(page);
    };

    const handleSelectedAccountsChange = (event) => {
        const value = event.target.value;
        setFormData({ ...formData, accounts_selection_type: event.target.value });

        if (value === 'manual') {
            openAccountsModal();
        }
    };

    const handleDropdownClick = (event) => {
        const value = event.target.dataset.value;

        if (value === 'manual') {
            openAccountsModal();
        }
    };

    const handleSelectAccount = (accountId) => {
        setFormData((prevState) => {
            const selected_accounts = prevState.selected_accounts.includes(accountId)
                ? prevState.selected_accounts.filter((id) => id !== accountId)
                : [...prevState.selected_accounts, accountId];
            return { ...prevState, selected_accounts };
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchAccounts(value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await axios.post(`${getApiUrl()}/api/groups/batch`, formData, {
                headers: { 'Content-Type': 'application/json' }
            });
            navigate('/groups');
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name.startsWith('message.')) {
            const key = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                message: {
                    ...prevData.message,
                    [key]: type === 'checkbox' ? checked : value,
                },
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    return (
        <>
            {/* Header Section */}
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('add_batch_groups')}
                </Typography>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray' }} />

            {/* Main Content */}
            <Paper sx={{ padding: 4, width: '100%', mt: 4, border: '1px solid lightgray' }} dir="rtl">

                {/* Account Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {t('account_settings')}
                </Typography>
                <Grid container spacing={3}>
                    {/* Selected Accounts Dropdown */}
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">{t('select_accounts')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                value={formData.accounts_selection_type}
                                onChange={handleSelectedAccountsChange}
                                onClick={handleDropdownClick}
                                displayEmpty
                            >
                                <MenuItem value="all">{t('all_authenticated_accounts')}</MenuItem>
                                <MenuItem value="manual">{t('manual_account_selection')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Number of Groups */}
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">{t('num_of_groups')}</Typography>
                        <TextField
                            type="number"
                            value={formData.groups_count}
                            onChange={(e) => setFormData({ ...formData, groups_count: Number(e.target.value) })}
                            fullWidth
                        />
                    </Grid>

                    {/* Number of Contacts */}
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">{t('num_of_contacts')}</Typography>
                        <TextField
                            type="number"
                            value={formData.contacts_count}
                            onChange={(e) => setFormData({ ...formData, contacts_count: Number(e.target.value) })}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {/* Group Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ mt: 4 }}>
                    {t('group_settings')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('title')}</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    {/* Description */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('description')}</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>

                    {/* Slow Mode */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">{t('slow_mode')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                name="slow_mode"
                                value={formData.slow_mode}
                                onChange={handleChange}
                            >
                                <MenuItem value={0}>{t('disabled')}</MenuItem>
                                <MenuItem value={10}>{t('10s')}</MenuItem>
                                <MenuItem value={30}>{t('30s')}</MenuItem>
                                <MenuItem value={60}>{t('1m')}</MenuItem>
                                <MenuItem value={300}>{t('5m')}</MenuItem>
                                <MenuItem value={900}>{t('15m')}</MenuItem>
                                <MenuItem value={3600}>{t('1h')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Chat History for New Members */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">{t('chat_history_for_new_members')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                name="chat_history_for_new_members"
                                value={formData.chat_history_for_new_members}
                                onChange={handleChange}
                            >
                                <MenuItem value="Visible">{t('visible')}</MenuItem>
                                <MenuItem value="Hidden">{t('hidden')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Event Messages */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">{t('event_messages')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                name="event_messages"
                                value={formData.event_messages}
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>{t('visible')}</MenuItem>
                                <MenuItem value={0}>{t('hidden')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Chat Reaction Setting */}
                    <Grid item xs={6}>
                        <Typography variant="subtitle1">{t('chat_reaction_setting')}</Typography>
                        <FormControl fullWidth>
                            <Select
                                name="chat_reaction_setting"
                                value={formData.chat_reaction_setting}
                                onChange={handleChange}
                            >
                                <MenuItem value="Disabled">{t('no_reaction')}</MenuItem>
                                <MenuItem value="All">{t('all_reactions')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Accesses Accordion */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('accesses')}</Typography>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="accesses-content"
                                id="accesses-header"
                            >
                                <Typography>{t('select')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset">
                                    <Grid container>
                                        {['ViewMembers', 'ViewAdmins', 'SendMessages', 'AddMember'].map((access) => (
                                            <Grid item xs={12} key={access}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.accesses.includes(access)}
                                                            onChange={(e) => {
                                                                const checked = e.target.checked;
                                                                setFormData((prevData) => ({
                                                                    ...prevData,
                                                                    accesses: checked
                                                                        ? [...prevData.accesses, access]
                                                                        : prevData.accesses.filter((a) => a !== access),
                                                                }));
                                                            }}
                                                            name={access}
                                                        />
                                                    }
                                                    label={t(access)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>

                {/* Message Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ mt: 4 }}>
                    {t('initial_message_settings')}
                </Typography>
                <Grid container spacing={3}>
                    {/* Initial Message */}
                    <Grid item xs={12} sx={{ mt: 0, mb: 0 }}>
                        <Typography variant="subtitle1" sx={{ mb: 0 }}>
                            {t('text')}
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="message.text"
                            value={formData.message.text}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent="flex-end" mt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                            width: '200px', height: '45px',
                            borderRadius: 2,
                            boxShadow: 2,
                            textTransform: 'none',
                            ':hover': { boxShadow: 4 }
                        }}
                    >
                        {t('submit')}
                    </Button>
                </Grid>
            </Paper>

            {/* Account Selection Modal */}
            <Dialog open={isAccountsModalOpen} onClose={() => setIsAccountsModalOpen(false)} fullWidth maxWidth="md">
                <DialogTitle dir="rtl">{t('select_accounts')}</DialogTitle>
                <DialogContent dividers>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Typography color="error">{t('error')}: {error}</Typography>
                    ) : (
                        <List>
                            {accounts.map((account) => (
                                <ListItem key={account.id} button onClick={() => handleSelectAccount(account.id)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={formData.selected_accounts.includes(account.id)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`ID: ${account.id} - ${account.account_name || 'No Name'} (${account.phone_number})`}
                                        secondary={`Status: ${account.is_active ? 'Active' : 'Inactive'}`}
                                        dir="rtl"
                                        style={{ textAlign: 'right' }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsAccountsModalOpen(false)}
                        size="large"
                        color="secondary"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'secondary.main',
                            },
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default StoreBatchGroupsPage