import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Box,
    Tooltip,
    Typography,
    ThemeProvider,
    createTheme,
    CssBaseline,
} from '@mui/material';
import {
    Home as HomeIcon,
    AccountCircle as AccountIcon,
    Contacts as ContactsIcon,
    Group as GroupIcon,
    Message as MessageIcon,
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';

const menuItems = [
    { text: 'home', path: '/', icon: HomeIcon },
    { text: 'accounts', path: '/accounts', icon: AccountIcon },
    { text: 'contacts', path: '/contacts', icon: ContactsIcon },
    { text: 'groups', path: '/groups', icon: GroupIcon },
    { text: 'messages', path: '/messages', icon: MessageIcon },
];

// Create a theme instance with RTL support
const theme = createTheme({
    direction: 'rtl',
    typography: {
        fontFamily: 'Vazirmatn, Arial, sans-serif',
    },
});

export default function Sidebar() {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const drawerWidth = isCollapsed ? 80 : 200;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                anchor="right"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#1e88e5',
                        color: '#fff',
                        transition: 'width 0.3s ease',
                        overflowX: 'hidden',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 2,
                        justifyContent: isCollapsed ? 'center' : 'space-between',
                    }}
                >
                    {!isCollapsed && (
                        <Typography variant="h6" noWrap component="div" dir="rtl" sx={{ textAlign: 'center', width: '100%' }}>
                            {t('menu')}
                        </Typography>
                    )}
                    <Tooltip title={isCollapsed ? t('expand') : t('collapse')} placement="left">
                        <IconButton onClick={toggleSidebar} sx={{ color: '#fff' }}>
                            {isCollapsed ? <ChevronLeftIcon /> : <ChevronRightIcon/>}
                        </IconButton>
                    </Tooltip>
                </Box>
                <List>
                    {menuItems.map((item) => {
                        const Icon = item.icon;
                        return (
                            <ListItem
                                button
                                key={item.text}
                                component={Link}
                                to={item.path}
                                selected={location.pathname === item.path}
                                sx={{
                                    justifyContent: isCollapsed ? 'center' : 'flex-start',
                                    color: '#fff',
                                    padding: '12px 20px',
                                    flexDirection: 'row-reverse', // Icon on the right
                                    transition: 'all 0.2s',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                        },
                                    },
                                }}
                            >
                                <ListItemIcon sx={{ color: '#fff', minWidth: isCollapsed ? 'auto' : 48 }}>
                                    <Icon />
                                </ListItemIcon>
                                {!isCollapsed && (
                                    <ListItemText
                                        primary={t(item.text)}
                                        sx={{
                                            direction: 'rtl', // Text in RTL
                                            textAlign: 'center', // Align text to the right
                                            marginRight: '12px', // Add space between text and icon
                                            '& .MuiListItemText-primary': {
                                                fontWeight: 500,
                                            },
                                        }}
                                    />
                                )}
                            </ListItem>
                        );
                    })}
                </List>


            </Drawer>
        </ThemeProvider>
    );
}