import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Visibility, Delete, Edit } from '@mui/icons-material';
import { getApiUrl, getQueryString } from '../../utils/config';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Typography, IconButton, Grid, Button,
    Divider, TextField, Box, Pagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions }
from '@mui/material';

const IndexGroupsPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ page, setPage ] = useState( 1 );
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ totalPages, setTotalPages ] = useState( 1 );
    const [ groupsData, setGroupsData ] = useState( [] );
    const [ selectedGroupId, setSelectedGroupId ] = useState( null );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );
    const [ filters, setFilters ] = useState( {
        id: '',
        title: '',
        account_id: '',
        guid: '',
    } );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    const fetchAllGroups = async ( filterParams = {}, currentPage = 1 ) => {
        setLoading(true);

        try {
            const queryString = getQueryString( filterParams, currentPage );

            const response = await ( await fetch( `${getApiUrl()}/api/groups/?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            setGroupsData( response.data );
            setTotalPages( response.summary.total_pages );
            setLoading( false );
        }
        catch ( err ) {
            setError( err.message );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
    };

    useEffect( () => {
        fetchAllGroups( filters, page );
    }, [ page ] );

    const handleDelete = async () => {
        setIsDeleteModalOpen( false );
        setLoading( true );

        try {
            const response = await ( await fetch( `${getApiUrl()}/api/groups/${selectedGroupId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            if ( response.data?.status === 'ERROR_GENERIC' ) {
                throw new Error( response.data.client_show_message?.link?.alert_data?.message ?? response.data.status_det );
            }
            else {
                setTimeout( () => {
                    setLoading( false );
                    setIsSuccessModalOpen( true );
                    fetchAllGroups( filters, page );
                }, 1000 );
            }
        }
        catch ( err ) {
            setError( `${t( 'delete_group_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
        finally {
            setSelectedGroupId( null );
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/groups` );
    };

    const handleOpenDeleteModal = ( groupId ) => {
        setSelectedGroupId( groupId );
        setIsDeleteModalOpen( true );
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen( false );
        setSelectedGroupId( null );
    };

    const handleFilterChange = ( event ) => {
        setFilters( {
            ...filters,
            [ event.target.name ]: event.target.value,
        } );
    };

    const handleApplyFilters = () => {
        setPage( 1 );
        fetchAllGroups( filters, 1 );
    };

    const handlePageChange = ( event, value ) => {
        setPage( value );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    return (
        <div>
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('groups')}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/groups/add/batch')}
                    sx={{ height: '40px', width: '200px' }}
                >
                    {t('add_new_group')}
                </Button>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

            <Grid container alignItems="center" spacing={2} style={{ marginBottom: '16px' }} dir="rtl">
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('id')}
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('title')}
                        name="title"
                        value={filters.title}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('account_id')}
                        name="account_id"
                        value={filters.account_id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('guid')}
                        name="guid"
                        value={filters.guid}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleApplyFilters}
                        sx={{
                            height: '40px',
                            width: '200px',
                            backgroundColor: 'gray',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'darkgray'
                            }
                        }}
                    >
                        {t('apply_filters')}
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} dir="rtl" sx={{ border: '1px solid lightgray' }}>
                <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('id')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('title')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('account_id')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('guid')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('members_count')}</TableCell>
                            <TableCell sx={{ width: '16.7%' }} align="center">{t('operation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupsData.map((group) => (
                            <TableRow key={group.id}>
                                <TableCell align="center">{group.id}</TableCell>
                                <TableCell align="center">{group.title}</TableCell>
                                <TableCell align="center">
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            padding: '6px 12px',  // More padding for a better button-like feel
                                            backgroundColor: '#f0f4ff',  // Softer background color (light blue)
                                            border: '2px solid #d1e3ff',  // Subtle border to match the background
                                            borderRadius: '12px',  // Rounded corners for a smoother look
                                            cursor: 'pointer',
                                            color: '#0d62b4',  // Darker blue for better contrast
                                            fontWeight: '500',  // Semi-bold for elegance
                                            transition: 'background-color 0.3s, box-shadow 0.3s, transform 0.3s',
                                            '&:hover': {
                                                backgroundColor: '#e0ebff',  // Slightly darker on hover (still soft)
                                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Soft shadow on hover
                                                transform: 'scale(1.03)',  // Subtle scale-up on hover
                                            },
                                            '&:active': {
                                                transform: 'scale(0.98)',  // Small scale-down on click for a pressed effect
                                            },
                                        }}
                                        onClick={() => navigate(`/accounts/${group.account_id}`)}
                                    >
                                        {group.account_id}
                                    </Box>
                                </TableCell>
                                <TableCell align="center">{group.guid}</TableCell>
                                <TableCell align="center">{group.members_count}</TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="view" color="primary" size="small" onClick={() => navigate(`/groups/${group.id}/`)}>
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="view" color="primary" size="small" onClick={() => navigate(`/groups/${group.id}/edit`)}>
                                        <Edit fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteModal(group.id)}>
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} dir="rtl">
                <TextField
                    label={t('go_to_page')}
                    type="number"
                    value={page}
                    onChange={(e) => setPage(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1, max: totalPages } }}
                    sx={{ width: '100px' }}
                />

                <Box flexGrow={1} display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ direction: 'ltr' }}
                    />
                </Box>
            </Box>

            {/* Error Modal */}
            <Dialog
                open={isErrorModalOpen}
                onClose={handleCloseErrorModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('error')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {error ? error : t('unknown_error')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseErrorModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('confirm_request')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('are_you_sure')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        style={{
                            backgroundColor: 'primary',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginLeft: '3px'
                        }}
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        onClick={handleCloseDeleteModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginRight: '3px'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog
                open={isSuccessModalOpen}
                onClose={handleCloseSuccessModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('operation_result')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('operation_succeeded')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseSuccessModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default IndexGroupsPage;
