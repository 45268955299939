import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Paper,
    Typography,
    Button,
    TextField,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';

const StoreGroupMessagePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( false );
    const [ messageId, setMessageId ] = useState( null );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );
    const [ formData, setFormData ] = useState( {
        text: '',
    } );

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const handleChange = ( event ) => {
        const { name, value, type, checked } = event.target;

        setFormData( ( prevData ) => ( {
            ...prevData,
            [ name ]: type === 'checkbox' ? checked : value,
        } ) );
    };

    const handleSubmit = async ( event ) => {
        event.preventDefault();
        setLoading( true );

        try {
            const group = await ( await fetch( `${getApiUrl()}/api/groups/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();


            if ( !group.data.account_id || !group.data.guid )
            {
                throw new Error( t('group_not_found') )
            }

            const response = await fetch(`${getApiUrl()}/api/messages`, {
                method: 'POST',
                body: JSON.stringify( {
                    ...formData,
                    receiver_type: 'group',
                    receiver_id: id,
                    account_id: group.data.account_id,
                    receiver_guid: group.data.guid
                } ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } );

            const data = await response.json();

            if ( response.ok ) {
                setTimeout( () => {
                    setLoading( false );
                    setMessageId( data.data.id);
                    setIsSuccessModalOpen( true );
                }, 1000 );
            }
            else
            {
                throw new Error( data.error ?? JSON.stringify( data ) );
            }
        }
        catch (err) {
            setError( `${t( 'store_message_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
        navigate( `/messages/${messageId}` );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/groups/${id}/messages/add` );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    return (
        <>
            {/* Header Section */}
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('add_new_message')}
                </Typography>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray' }} />

            {/* Main Content */}
            <Paper sx={{ padding: 4, width: '100%', mt: 4, border: '1px solid lightgray' }} dir="rtl">

                {/* Message Settings Section */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {t('message_settings')}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">{t('text')}</Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="text"
                            value={formData.text}
                            onChange={handleChange}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>

                {/* Submit Button */}
                <Grid container justifyContent="flex-end" mt={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{
                            width: '200px', height: '45px',
                            borderRadius: 2,
                            boxShadow: 2,
                            textTransform: 'none',
                            ':hover': { boxShadow: 4 }
                        }}
                    >
                        {t('submit')}
                    </Button>
                </Grid>

                {/* Error Modal */}
                <Dialog
                    open={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('error')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {error ? error : t('unknown_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseErrorModal}
                            variant="contained"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Success Modal */}
                <Dialog
                    open={isSuccessModalOpen}
                    onClose={handleCloseSuccessModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{
                        style: { padding: '20px', borderRadius: '12px' },
                    }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('operation_result')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {t('operation_succeeded')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseSuccessModal}
                            color="primary"
                            variant="contained"
                            style={{
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default StoreGroupMessagePage;
