import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import {getApiUrl, getQueryString} from '../../utils/config';
import { Visibility, Delete, Edit } from '@mui/icons-material';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box, Divider, TextField, Pagination, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const IndexContactsPage = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef( null );
    const { t, i18n } = useTranslation();
    const [ page, setPage ] = useState( 1 );
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ totalPages, setTotalPages ] = useState( 1 );
    const [ hasAccount, setHasAccount ] = useState( '' );
    const [ uploading, setUploading ] = useState( false );
    const [ contactsData, setContactsData ] = useState( [] );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ selectedContactId, setSelectedContactsId ] = useState( null );
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );
    const [ filters, setFilters ] = useState( {
        id: '',
        phone_number: '',
        full_name: '',
        has_account: '',
    } );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    const fetchContacts = async ( filterParams = {}, currentPage = 1 ) => {
        setLoading( true );

        try {
            const queryString = getQueryString( filterParams, currentPage );

            const response = await ( await fetch( `${getApiUrl()}/api/contacts/?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            setContactsData( response.data );
            setTotalPages( response.summary.total_pages );
            setLoading( false );
        }
        catch ( err ) {
            setError( `${t( 'index_contacts_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
    };

    useEffect(() => {
        fetchContacts( filters, page );
    }, [ page ] );

    const handleDelete = async () => {
        setIsDeleteModalOpen( false );
        setLoading( true );

        try {
            const response = await ( await fetch( `${getApiUrl()}/api/contacts/${selectedContactId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            if ( response.data?.status === 'ERROR_GENERIC' ) {
                throw new Error( response.data.client_show_message?.link?.alert_data?.message ?? response.data.status_det );
            }
            else {
                setTimeout( () => {
                    setLoading( false );
                    setIsSuccessModalOpen( true );
                    fetchContacts( filters, page );
                }, 1000 );
            }
        }
        catch ( err ) {
            setError( `${t( 'delete_contact_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
        finally {
            setSelectedContactsId( null );
        }
    };

    const handleFileUpload = async ( file ) => {
        setUploading( true );

        try {
            const formData = new FormData();
            formData.append( 'file', file );

            await fetch(`${getApiUrl()}/api/contacts/batch`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            } );

            // await axiosInstance.post(`/api/contacts/batch`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });

            setTimeout( () => {
                setUploading( false );
                setIsSuccessModalOpen( true );
                fetchContacts();
            }, 1000 );
        }
        catch ( err ) {
            setError( `${t( 'upload_file_failed' )}: ${err.message}` );
            setUploading( false );
        }
        finally {
            if ( fileInputRef.current ) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/contacts` );
    };

    const handleOpenDeleteModal = ( accountId ) => {
        setSelectedContactsId( accountId );
        setIsDeleteModalOpen( true );
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen( false );
        setSelectedContactsId( null );
    };

    const handleFilterChange = ( event ) => {
        const { name, value } = event.target;

        let updatedFilters = { ...filters };

        if ( name === 'has_account' ) {
            if ( value === '' ) {
                setHasAccount( '' );
                updatedFilters.has_account = '';
            }
            else if ( value === 'member' ) {
                setHasAccount( 'member' );
                updatedFilters.has_account = 1;
            }
            else if ( value === 'not_member' ) {
                setHasAccount( 'not_member' );
                updatedFilters.has_account = 0;
            }
        }
        else {
            updatedFilters[ name ] = value;
        }

        setFilters( updatedFilters );
    };

    const handleApplyFilters = () => {
        setPage( 1 );
        fetchContacts( filters, 1 );
    };

    const handlePageChange = ( event, value ) => {
        setPage( value );
    };

    // Loading state
    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    return (
        <div>

            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('contacts')}
                </Typography>

                <Button variant="contained" color="primary" component="label" sx={{ height: '40px', width: '200px' }}>
                    {t('upload_file')}
                    <input
                        type="file"
                        hidden
                        ref={fileInputRef} // Attach ref to file input
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                    />
                </Button>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

            <Grid container alignItems="center" spacing={2} style={{ marginBottom: '16px' }} dir="rtl">
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('id')}
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('phone_number')}
                        name="phone_number"
                        value={filters.phone_number}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('full_name')}
                        name="full_name"
                        value={filters.full_name}
                        onChange={handleFilterChange}
                    />
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>{t('has_account')}</InputLabel>
                        <Select
                            name="has_account"
                            value={hasAccount}
                            onChange={handleFilterChange}
                            displayEmpty
                        >
                            <MenuItem value="">{t('all')}</MenuItem>
                            <MenuItem value="member">{t('member')}</MenuItem>
                            <MenuItem value="not_member">{t('not_member')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleApplyFilters}
                        sx={{
                            height: '40px',
                            width: '200px',
                            backgroundColor: 'gray',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'darkgray'
                            }
                        }}
                    >
                        {t('apply_filters')}
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} dir="rtl" sx={{ border: '1px solid lightgray' }}>
                <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('id')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('phone_number')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('full_name')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('has_account')}</TableCell>
                            <TableCell sx={{ width: '16.66%' }} align="center">{t('groups_count')}</TableCell>
                            <TableCell sx={{ width: '16.7%' }} align="center">{t('operation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactsData.map( ( contact ) => (
                            <TableRow key={contact.id}>
                                <TableCell align="center">{contact.id}</TableCell>
                                <TableCell align="center">{contact.phone_number}</TableCell>
                                <TableCell align="center">{contact.full_name}</TableCell>
                                <TableCell align="center">{contact.has_account === null ? t('unknown') : contact.has_account ? t('member') : t('not_member')}</TableCell>
                                <TableCell align="center">{contact.groups_count}</TableCell>
                                <TableCell align="center">
                                    <IconButton aria-label="view" color="primary" size="small" onClick={() => navigate(`/contacts/${contact.id}/`)}>
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="edit" color="primary" size="small" onClick={() => navigate(`/contacts/${contact.id}/edit`)}>
                                        <Edit fontSize="small" />
                                    </IconButton>
                                    <IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteModal(contact.id)}>
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} dir="rtl">
                <TextField
                    label={t('go_to_page')}
                    type="number"
                    value={page}
                    onChange={(e) => setPage(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1, max: totalPages } }}
                    sx={{ width: '100px' }}
                />

                <Box flexGrow={1} display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ direction: 'ltr' }}
                    />
                </Box>
            </Box>

            {/* uploading Loading */}
            {uploading && <LoadingComponent />}

            {/* Error Modal */}
            <Dialog
                open={isErrorModalOpen}
                onClose={handleCloseErrorModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('error')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {error ? error : t('unknown_error')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseErrorModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('confirm_request')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('are_you_sure')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        style={{
                            backgroundColor: 'primary',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginLeft: '3px'
                        }}
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        onClick={handleCloseDeleteModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginRight: '3px'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog
                open={isSuccessModalOpen}
                onClose={handleCloseSuccessModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('operation_result')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('operation_succeeded')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseSuccessModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default IndexContactsPage;
