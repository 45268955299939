import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    Divider,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Checkbox, FormControl, Select, MenuItem
} from '@mui/material';

const UpdateGroupPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ accountData, setAccountData ] = useState( null );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    useEffect( () => {
        const fetchGroup = async () => {
            try {
                const response = await fetch( `${getApiUrl()}/api/groups/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } );

                const data = await response.json();

                if ( response.ok ) {
                    setAccountData( data.data );
                    setLoading( false );
                }
                else
                {
                    throw new Error( data.error ?? JSON.stringify( data ) );
                }
            }
            catch ( err ) {
                setError( `${t( 'show_group_failed' )}: ${err.message}` );
                setLoading( false );
                setIsErrorModalOpen( true );
            }
        };

        fetchGroup();
    }, [ id ] );

    const handleChange = ( event ) => {
        const { name, value, type, checked } = event.target;

        if ( name === "accesses" ) {
            const currentAccesses = Array.isArray( accountData.accesses ) ? accountData.accesses : ( typeof accountData.accesses === "string" ? JSON.parse( accountData.accesses ) : [] );
            const newAccesses = checked ? [...currentAccesses, value] : currentAccesses.filter( ( access ) => access !== value );
            setAccountData( ( prevData ) => ( {
                ...prevData,
                [ name ]: newAccesses,
            } ) );
        }
        else {
            setAccountData( ( prevData ) => ( {
                ...prevData,
                [ name ]: type === "checkbox" ? checked : value,
            } ) );
        }
    };


    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(`${getApiUrl()}/api/groups/${id}`, {
                method: 'PUT',
                body: JSON.stringify( accountData ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } );

            const data = await response.json();

            if ( response.ok ) {
                setTimeout( () => {
                    setIsSubmitting( false );
                    setAccountData( data.data );
                    setIsSuccessModalOpen( true );
                }, 1000 );
            }
            else
            {
                throw new Error( data.error ?? JSON.stringify( data ) );
            }
        }
        catch ( err ) {
            setError( `${t( 'update_group_failed' )}: ${err.message}` );
            setIsSubmitting( false );
            setIsErrorModalOpen( true );
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
        navigate( `/groups/${id}` );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/groups/${id}/edit` );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    if ( isErrorModalOpen )
    {
        return (
            <>
                <Dialog
                    open={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('error')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {error ? error : t('unknown_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseErrorModal}
                            variant="contained"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <Paper sx={{ p: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('group_details')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('id')}
                            </Typography>
                            <TextField
                                value={accountData.id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_id')}
                            </Typography>
                            <TextField
                                value={accountData.account_id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('guid')}
                            </Typography>
                            <TextField
                                value={accountData.guid}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('members_count')}
                            </Typography>
                            <TextField
                                value={accountData.members_count}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('title')}
                            </Typography>
                            <TextField
                                name="title"
                                value={accountData.title}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('slow_mode')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="slow_mode"
                                    value={accountData.slow_mode}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>{t('disabled')}</MenuItem>
                                    <MenuItem value={10}>{t('10s')}</MenuItem>
                                    <MenuItem value={30}>{t('30s')}</MenuItem>
                                    <MenuItem value={60}>{t('1m')}</MenuItem>
                                    <MenuItem value={300}>{t('5m')}</MenuItem>
                                    <MenuItem value={900}>{t('15m')}</MenuItem>
                                    <MenuItem value={3600}>{t('1h')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('chat_history_for_new_members')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="chat_history_for_new_members"
                                    value={accountData.chat_history_for_new_members}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Visible">{t('visible')}</MenuItem>
                                    <MenuItem value="Hidden">{t('hidden')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('event_messages')}
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="event_messages"
                                    value={accountData.event_messages}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>{t('visible')}</MenuItem>
                                    <MenuItem value={0}>{t('hidden')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('description')}
                            </Typography>
                            <TextField
                                name="description"
                                value={accountData.description}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                sx={{
                                    textAlign: 'right',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('accesses')}
                            </Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                {["ViewMembers", "ViewAdmins", "SendMessages", "AddMember"].map((accessOption, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            name="accesses"
                                            value={accessOption}
                                            checked={
                                                accountData.accesses &&
                                                (typeof accountData.accesses === 'string'
                                                    ? JSON.parse(accountData.accesses)
                                                    : accountData.accesses).includes(accessOption)
                                            }
                                            onChange={handleChange}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: 'primary.main'
                                                },
                                            }}
                                        />
                                        <Typography variant="body2">
                                            {t(accessOption)}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {isSubmitting ? t('is_updating') : t('submit_changes')}
                        </Button>
                    </Grid>

                    {isSubmitting && ( <> <LoadingComponent /> </>)}
                </Box>

                {/* Success Modal */}
                <Dialog
                    open={isSuccessModalOpen}
                    onClose={handleCloseSuccessModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{
                        style: { padding: '20px', borderRadius: '12px' },
                    }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('operation_result')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {t('update_group_succeeded')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseSuccessModal}
                            color="primary"
                            variant="contained"
                            style={{
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default UpdateGroupPage;
