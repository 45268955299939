import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    Divider,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog
} from '@mui/material';

const UpdateMessagePage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ accountData, setAccountData ] = useState( null );
    const [ isSubmitting, setIsSubmitting ] = useState( false );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    useEffect( () => {
        const fetchMessage = async () => {
            try {
                const response = await fetch( `${getApiUrl()}/api/messages/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } );

                const data = await response.json();

                if ( response.ok ) {
                    setAccountData( data.data );
                    setLoading( false );
                }
                else
                {
                    throw new Error( data.error ?? JSON.stringify( data ) );
                }
            }
            catch ( err ) {
                setError( `${t( 'show_message_failed' )}: ${err.message}` );
                setLoading( false );
                setIsErrorModalOpen( true );
            }
        };

        fetchMessage();
    }, [ id ] );

    const handleChange = ( event ) => {
        const { name, value, type, checked } = event.target;

        setAccountData( ( prevData ) => ( {
            ...prevData,
            [ name ]: type === "checkbox" ? checked : value,
        } ) );
    };

    const handleSubmit = async ( e ) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(`${getApiUrl()}/api/messages/${id}`, {
                method: 'PUT',
                body: JSON.stringify( accountData ),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            } );

            const data = await response.json();

            if ( response.ok ) {
                setTimeout( () => {
                    setIsSubmitting( false );
                    setAccountData( data.data );
                    setIsSuccessModalOpen( true );
                }, 1000 );
            }
            else
            {
                throw new Error( data.error ?? JSON.stringify( data ) );
            }
        }
        catch ( err ) {
            setError( `${t( 'update_message_failed' )}: ${err.message}` );
            setIsSubmitting( false );
            setIsErrorModalOpen( true );
        }
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
        navigate( `/messages/${id}` );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/messages/${id}/edit` );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    if ( isErrorModalOpen )
    {
        return (
            <>
                <Dialog
                    open={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('error')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {error ? error : t('unknown_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseErrorModal}
                            variant="contained"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <Paper sx={{ p: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('message_details')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('id')}
                            </Typography>
                            <TextField
                                value={accountData.id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_id')}
                            </Typography>
                            <TextField
                                value={accountData.account_id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('message_id')}
                            </Typography>
                            <TextField
                                value={accountData.message_id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('sender_guid')}
                            </Typography>
                            <TextField
                                value={accountData.sender_guid}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('receiver_type')}
                            </Typography>
                            <TextField
                                value={accountData.receiver_type}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('receiver_id')}
                            </Typography>
                            <TextField
                                value={accountData.receiver_id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('text')}
                            </Typography>
                            <TextField
                                name="text"
                                value={accountData.text}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                sx={{
                                    textAlign: 'right',
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {isSubmitting ? t('is_updating') : t('submit_changes')}
                        </Button>
                    </Grid>

                    {isSubmitting && ( <> <LoadingComponent /> </>)}
                </Box>

                {/* Success Modal */}
                <Dialog
                    open={isSuccessModalOpen}
                    onClose={handleCloseSuccessModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{
                        style: { padding: '20px', borderRadius: '12px' },
                    }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('operation_result')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {t('update_group_succeeded')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseSuccessModal}
                            color="primary"
                            variant="contained"
                            style={{
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </>
    );
};

export default UpdateMessagePage;
