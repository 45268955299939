import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {useTranslation} from "react-i18next";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HomePage = () => {
    const { t, i18n } = useTranslation();

    const data = {
        accounts: 25,
        contacts: 50,
        groups: 10,
        messages: 100,
    };

    // Sample data for the charts
    const messageData = {
        labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
        datasets: [
            {
                label: 'Messages Sent',
                data: [10, 20, 30, 25, 15, 30, 40], // Replace with real data
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const groupData = {
        labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
        datasets: [
            {
                label: 'Groups Created',
                data: [1, 2, 1, 3, 1, 2, 4], // Replace with real data
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    return (
        <Grid container spacing={4}>
            {Object.keys(data).map((key) => (
                <Grid item xs={12} sm={6} md={3} key={key}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom sx={{ direction: 'rtl' }}>
                            {t(key)}
                        </Typography>
                        <Typography variant="h4" >{data[key]}</Typography>
                        <Button variant="contained" sx={{ marginTop: 2 }} component={Link} to={`/${key}`}>
                            {t('go_to_page') + ' ' +  t(key)}
                        </Button>
                    </Paper>
                </Grid>
            ))}
            <Grid item xs={12} md={6} sx={{ marginTop: 4 }}> {/* Add margin here */}
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            direction: 'rtl',
                            marginBottom: 4, // Adjust margin as needed
                        }}
                    >
                        {t('messages_report_title')}
                    </Typography>
                    <Bar data={messageData} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: 4 }}> {/* Add margin here */}
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            direction: 'rtl',
                            marginBottom: 4, // Adjust margin as needed
                        }}
                    >
                        {t('groups_report_title')}
                    </Typography>
                    <Bar data={groupData} />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HomePage;
