import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Button, TextField, Box, CircularProgress, Alert, Container } from '@mui/material';
import {useTranslation} from "react-i18next";
import {getApiUrl} from "../../utils/config";

const AuthenticateAccountPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [step, setStep] = useState('initial');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const handleStart = async () => {
        setLoading(true);
        try {
            await axios.post(`${getApiUrl()}/api/services/${id}/send_code`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setStep('verify');
            setMessage(t('code_has_sent'));
        } catch (err) {
            setError(t('send_code_failed'));
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${getApiUrl()}/api/services/${id}/sign_in`, {
                code: code,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log(response);

            if (response.data?.data?.status === 'OK') {
                setMessage(t('valid_code'));
                setStep('success');
            }
            else {
                setMessage(t('invalid_code'));
                setStep('failed');
            }
        } catch (err) {
            setMessage(t('sign_in_failed'));
            setStep('failed');
        } finally {
            setLoading(false);
        }
    };

    const handleReauth = () => {
        setStep('initial');
        setMessage('');
        setError('');
    };

    const handleBackToAccounts = () => {
        navigate('/accounts');
    };

    const handleBackToAccountDetails = () => {
        navigate(`/accounts/${id}`);
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {loading && <CircularProgress />}
                {error && <Alert severity="error">{error}</Alert>}
                {/*{message && !error && <Alert severity="info">{message}</Alert>}*/}

                <Box
                    sx={{
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        p: 3,
                        mt: 3,
                        backgroundColor: '#f9f9f9'
                    }}
                >
                    {step === 'initial' && (
                        <Box sx={{ width: '100%', direction: 'rtl', textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Typography variant="h6" align="right">
                                {t('start_authentication_process')}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleStart}
                                sx={{ mt: 5, width: '100%' }} // Increased margin-top
                            >
                                {t('start')}
                            </Button>
                        </Box>
                    )}

                    {step === 'verify' && (
                        <Box sx={{ width: '100%', direction: 'rtl', textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Typography variant="h6" align="right">{message}</Typography>
                            <TextField
                                label={t('authentication_code')}
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                fullWidth
                                sx={{ mt: 2 }}
                            />
                            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2, width: '100%' }}>
                                {t('submit')}
                            </Button>
                            <Button variant="outlined" color="primary" onClick={handleReauth} sx={{ mt: 2, width: '100%' }}>
                                {t('send_code_again')}
                            </Button>
                        </Box>
                    )}

                    {step === 'failed' && (
                        <Box sx={{ width: '100%', direction: 'rtl', textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Typography variant="h6" align="right">{message}</Typography>
                            <Button variant="contained" color="primary" onClick={handleReauth} sx={{ mt: 2, width: '100%' }}>
                                {t('send_code_again')}
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleBackToAccounts} sx={{ mt: 2, width: '100%' }}>
                                {t('back_to_accounts')}
                            </Button>
                        </Box>
                    )}

                    {step === 'success' && (
                        <Box
                            sx={{
                                width: '100%',
                                direction: 'rtl', // Ensure text direction is RTL
                                textAlign: 'right', // Align text to the right
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end' // Align children to the right end
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{ textAlign: 'right', width: '100%' }} // Ensure text alignment is right
                            >
                                {message}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={handleBackToAccounts} sx={{ mt: 4, width: '100%' }}>
                                {t('back_to_accounts')}
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleBackToAccountDetails} sx={{ mt: 2, width: '100%' }}>
                                {t('back_to_account_detail')}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default AuthenticateAccountPage;
