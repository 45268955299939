import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import React, { useState, useEffect } from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { getApiUrl, getQueryString } from '../../utils/config';
import LoadingComponent from '../../components/LoadingComponent';
import { Visibility, Delete, Chat, Group } from '@mui/icons-material';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    Typography,
    IconButton,
    Button,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Pagination,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle, DialogContentText
} from '@mui/material';

const IndexAccountsPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ page, setPage ] = useState( 1 );
    const [ error, setError ] = useState( null );
    const [ status, setStatus ] = useState( '' );
    const [ loading, setLoading ] = useState( true );
    const [ totalPages, setTotalPages ] = useState( 1 );
    const [ accountsData, setAccountsData ] = useState( [] );
    const [ authModalMessage, setAuthModalMessage ] = useState( '' );
    const [ isAuthModalOpen, setIsAuthModalOpen ] = useState( false );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );
    const [ selectedAccountId, setSelectedAccountId ] = useState( null );
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState( false );
    const [ isSuccessModalOpen, setIsSuccessModalOpen ] = useState( false );
    const [ filters, setFilters ] = useState( {
        id: '',
        account_name: '',
        phone_number: '',
        is_active: '',
    } );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    const fetchAllAccounts = async ( filterParams = {}, currentPage = 1 ) => {
        setLoading( true );

        try {
            const queryString = getQueryString( filterParams, currentPage );

            const response = await ( await fetch( `${getApiUrl()}/api/accounts/?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            setAccountsData( response.data );
            setTotalPages( response.summary.total_pages );
            setLoading( false );
        }
        catch ( err )
        {
            setError( `${t( 'index_accounts_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
    };

    useEffect(  () => {
        fetchAllAccounts( filters, page );
    }, [ page ] );

    const handleDelete = async () => {
        setIsDeleteModalOpen( false );
        setLoading( true );

        try {
            const response = await ( await fetch( `${getApiUrl()}/api/accounts/${selectedAccountId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            } ) ).json();

            if ( response.data?.status === 'ERROR_GENERIC' ) {
                throw new Error( response.data.client_show_message?.link?.alert_data?.message ?? response.data.status_det );
            }
            else {
                setTimeout( () => {
                    setLoading( false );
                    setIsSuccessModalOpen( true );
                    fetchAllAccounts( filters, page );
                }, 1000 );
            }
        }
        catch ( err ) {
            setError( `${t( 'delete_account_failed' )}: ${err.message}` );
            setLoading( false );
            setIsErrorModalOpen( true );
        }
        finally {
            setSelectedAccountId( null );
        }
    };

    const handleFilterChange = ( event ) => {
        const { name, value } = event.target;

        let updatedFilters = { ...filters };

        if ( name === 'is_active' ) {
            if ( value === '' ) {
                setStatus( '' );
                updatedFilters.is_active = '';
            }
            else if ( value === 'active' ) {
                setStatus( 'active' );
                updatedFilters.is_active = 1;
            }
            else if ( value === 'not_active' ) {
                setStatus( 'not_active' );
                updatedFilters.is_active = 0;
            }
        }
        else {
            updatedFilters[ name ] = value;
        }

        setFilters( updatedFilters );
    };

    const handleApplyFilters = () => {
        setPage( 1 ); // Reset to first page on filter apply
        fetchAllAccounts( filters, 1 );
    };

    const handlePageChange = ( event, value ) => {
        setPage(value);
    };

    const handleActionClick = ( account ) => {
        if ( account.auth ) {
            setAuthModalMessage( t( 'reauthenticate_request' ) );
        }
        else {
            setAuthModalMessage( t( 'authenticate_request' ) );
        }

        setSelectedAccountId( account.id );
        setIsAuthModalOpen( true );
    };

    const handleConfirm = () => {
        setIsAuthModalOpen( false );
        navigate( `/accounts/${selectedAccountId}/auth` );
        setSelectedAccountId( null );
    };

    const handleCloseSuccessModal = () => {
        setIsSuccessModalOpen( false );
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( `/accounts` );
    };

    const handleOpenDeleteModal = ( accountId ) => {
        setSelectedAccountId( accountId );
        setIsDeleteModalOpen( true );
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen( false );
        setSelectedAccountId( null );
    };

    // Loading state
    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    return (
        <div>
            <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                <Typography variant="h5" fontWeight="bold">
                    {t('accounts')}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/accounts/add')}
                    sx={{ height: '40px', width: '200px' }}
                >
                    {t('add_new_account')}
                </Button>
            </Grid>

            <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

            <Grid container alignItems="center" spacing={2} style={{ marginBottom: '16px' }} dir="rtl">
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('id')}
                        name="id"
                        value={filters.id}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('account_name')}
                        name="account_name"
                        value={filters.account_name}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('phone_number')}
                        name="phone_number"
                        value={filters.phone_number}
                        onChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>{t('status')}</InputLabel>
                        <Select
                            name="is_active"
                            value={status}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">{t('all')}</MenuItem>
                            <MenuItem value="active">{t('active')}</MenuItem>
                            <MenuItem value="not_active">{t('not_active')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={handleApplyFilters}
                        sx={{
                            height: '40px',
                            width: '200px',
                            backgroundColor: 'gray',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'darkgray'
                            }
                        }}
                    >
                        {t('apply_filters')}
                    </Button>
                </Grid>
            </Grid>

            {/* Accounts Table */}
            <TableContainer component={Paper} dir="rtl" sx={{ border: '1px solid lightgray' }}>
                <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: '16.6%' }} align="center">{t('id')}</TableCell>
                            <TableCell sx={{ width: '16.6%' }} align="center">{t('account_name')}</TableCell>
                            <TableCell sx={{ width: '16.6%' }} align="center">{t('phone_number')}</TableCell>
                            <TableCell sx={{ width: '16.6%' }} align="center">{t('status')}</TableCell>
                            <TableCell sx={{ width: '16.6%' }} align="center">{t('auth_status')}</TableCell>
                            <TableCell sx={{ width: '17%' }} align="center">{t('operation')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountsData.map((account) => (
                            <TableRow key={account.id}>
                                <TableCell sx={{ width: '16.6%' }} align="center">{account.id}</TableCell>
                                <TableCell sx={{ width: '16.6%' }} align="center">{account.account_name}</TableCell>
                                <TableCell sx={{ width: '16.6%' }} align="center">{account.phone_number}</TableCell>
                                <TableCell sx={{ width: '16.6%' }} align="center">{account.is_active ? t('active') : t('not_active') }</TableCell>
                                <TableCell sx={{ width: '16.6%' }} align="center">
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-flex',  // Inline flex to properly align the icon and text
                                            alignItems: 'center',
                                            padding: '6px 10px',  // Same padding as the second one
                                            backgroundColor: '#f0f4ff',  // Softer background color (light blue)
                                            border: '2px solid #d1e3ff',  // Subtle border to match the background
                                            borderRadius: '12px',  // Rounded corners for a smoother look
                                            cursor: 'pointer',
                                            color: '#0d62b4',  // Darker blue for better contrast
                                            fontWeight: '500',  // Semi-bold for elegance
                                            transition: 'background-color 0.3s, box-shadow 0.3s, transform 0.3s',
                                            '&:hover': {
                                                backgroundColor: '#e0ebff',  // Slightly darker on hover (still soft)
                                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Soft shadow on hover
                                                transform: 'scale(1.03)',  // Subtle scale-up on hover
                                            }
                                        }}
                                        onClick={() => handleActionClick(account)}
                                    >
                                        {/* Text */}
                                        {account.auth ? t('authenticate') : t('not_authenticate')}

                                        {/* Icon */}
                                        <IconButton sx={{ padding: 0, marginRight: '8px', color: '#0d62b4' }} disableRipple>
                                            {account.auth ? <LockIcon /> : <LockOpenIcon />}
                                        </IconButton>
                                    </Box>
                                </TableCell>

                                <TableCell sx={{ width: '17%' }} align="center">
                                    {/* Icons for operations: Edit, Show, Delete */}
                                    <IconButton
                                        aria-label="show"
                                        color="primary"
                                        size="small"
                                        onClick={() => navigate(`/accounts/${account.id}/edit`)}
                                    >
                                        <Visibility fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="in-progress"
                                        color="secondary"
                                        size="small"
                                        onClick={() => navigate(`/accounts/${account.id}/groups`)}
                                    >
                                        <Group fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="edit"
                                        color="primary"
                                        size="small"
                                        onClick={() => navigate(`/accounts/${account.id}/messages`)}
                                    >
                                        <Chat fontSize="small" />
                                    </IconButton>
                                    <IconButton
                                        aria-label="delete"
                                        color="error"
                                        size="small"
                                        onClick={() => handleOpenDeleteModal(account.id)}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Section */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} dir="rtl">
                <TextField
                    label={t('go_to_page')}
                    type="number"
                    value={page}
                    onChange={(e) => setPage(Number(e.target.value))}
                    InputProps={{ inputProps: { min: 1, max: totalPages } }}
                    sx={{ width: '100px' }}
                />

                <Box flexGrow={1} display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        sx={{ direction: 'ltr' }}
                    />
                </Box>
            </Box>

            {/* Auth Modal */}
            <Dialog
                open={isAuthModalOpen}
                onClose={() => setIsAuthModalOpen(false)}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('confirm_request')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '18px',
                            textAlign: 'right',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {authModalMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleConfirm}
                        variant="contained"
                        style={{
                            backgroundColor: 'primary',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginLeft: '3px'
                        }}
                    >
                        {t('confirm')}
                    </Button>
                    <Button
                        onClick={() => setIsAuthModalOpen(false)}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginRight: '3px'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Modal */}
            <Dialog
                open={isErrorModalOpen}
                onClose={handleCloseErrorModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('error')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {error ? error : t('unknown_error')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseErrorModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Modal */}
            <Dialog
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('confirm_request')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('are_you_sure')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleDelete}
                        variant="contained"
                        style={{
                            backgroundColor: 'primary',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginLeft: '3px'
                        }}
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        onClick={handleCloseDeleteModal}
                        variant="contained"
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                            width: '20%',
                            marginRight: '3px'
                        }}
                    >
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Modal */}
            <Dialog
                open={isSuccessModalOpen}
                onClose={handleCloseSuccessModal}
                maxWidth="xs"
                fullWidth
                dir="rtl"
                PaperProps={{
                    style: { padding: '20px', borderRadius: '12px' },
                }}
            >
                <DialogTitle
                    dir="rtl"
                    style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                >
                    {t('operation_result')}
                </DialogTitle>
                <DialogContent dir="rtl">
                    <DialogContentText
                        dir="rtl"
                        style={{
                            fontSize: '20px',
                            textAlign: 'center',
                            color: '#555',
                            marginBottom: '10px',
                        }}
                    >
                        {t('delete_account_succeeded')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    dir="rtl"
                    style={{
                        justifyContent: 'center',
                        paddingBottom: '15px',
                    }}
                >
                    <Button
                        onClick={handleCloseSuccessModal}
                        color="primary"
                        variant="contained"
                        style={{
                            fontWeight: 'bold',
                            padding: '12px 24px',
                            fontSize: '16px',
                            borderRadius: '15px',
                        }}
                    >
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default IndexAccountsPage;
