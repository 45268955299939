import { getApiUrl } from '../../utils/config';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import LoadingComponent from '../../components/LoadingComponent';
import {
    Grid,
    Typography,
    Paper,
    Box,
    TextField,
    Button,
    Divider,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
} from '@mui/material';

const ShowAccountPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [ error, setError ] = useState( null );
    const [ loading, setLoading ] = useState( true );
    const [ accountData, setAccountData ] = useState( null );
    const [ isErrorModalOpen, setIsErrorModalOpen ] = useState( false );

    useEffect( () => {
        i18n.changeLanguage( 'fa' );
    }, [ i18n ] );

    useEffect( () => {
        const fetchAccount = async () => {
            try {
                const response = await fetch( `${getApiUrl()}/api/accounts/${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                } );

                const data = await response.json();

                if ( response.ok ) {
                    setAccountData( data.data );
                    setLoading( false );
                }
                else
                {
                    throw new Error( data.error ?? JSON.stringify( data ) );
                }
            }
            catch ( err ) {
                setError( `${t( 'show_account_failed' )}: ${err.message}` );
                setLoading( false );
                setIsErrorModalOpen( true );
            }
        };

        fetchAccount();
    }, [ id ] );

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen( false );
        navigate( '/accounts' );
    };

    if ( loading ) {
        return (
            <>
                <LoadingComponent />
            </>
        );
    }

    if ( isErrorModalOpen )
    {
        return (
            <>
                <Dialog
                    open={isErrorModalOpen}
                    onClose={handleCloseErrorModal}
                    maxWidth="xs"
                    fullWidth
                    dir="rtl"
                    PaperProps={{ style: { padding: '20px', borderRadius: '12px' } }}
                >
                    <DialogTitle
                        dir="rtl"
                        style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}
                    >
                        {t('error')}
                    </DialogTitle>
                    <DialogContent dir="rtl">
                        <DialogContentText
                            dir="rtl"
                            style={{
                                fontSize: '20px',
                                textAlign: 'center',
                                color: '#555',
                                marginBottom: '10px',
                            }}
                        >
                            {error ? error : t('unknown_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        dir="rtl"
                        style={{
                            justifyContent: 'center',
                            paddingBottom: '15px',
                        }}
                    >
                        <Button
                            onClick={handleCloseErrorModal}
                            variant="contained"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                fontWeight: 'bold',
                                padding: '12px 24px',
                                fontSize: '16px',
                                borderRadius: '15px',
                            }}
                        >
                            {t('close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <>
            <Paper sx={{ p: 3 }}>
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '16px' }} dir="rtl">
                    <Typography variant="h5" fontWeight="bold">
                        {t('account_details')}
                    </Typography>
                </Grid>

                <Divider sx={{ backgroundColor: 'lightgray', marginBottom: '16px' }} />

                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '100%',
                        direction: 'rtl',
                    }}
                >
                    <Grid container spacing={2} direction="row" alignItems="flex-start">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('id')}
                            </Typography>
                            <TextField
                                value={accountData.id}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('phone_number')}
                            </Typography>
                            <TextField
                                value={accountData.phone_number}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('guid')}
                            </Typography>
                            <TextField
                                value={accountData.guid}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('auth')}
                            </Typography>
                            <TextField
                                value={accountData.auth}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('account_name')}
                            </Typography>
                            <TextField
                                value={accountData.account_name}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('status')}
                            </Typography>
                            <TextField
                                value={accountData.is_active ? t('active') : t('not_active')}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        '& input': {
                                            cursor: 'not-allowed'
                                        },
                                    },
                                }}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textAlign: 'right',
                                    backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold" color="textSecondary" sx={{ mb: 1 }}>
                                {t('auth_status')}
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <TextField
                                        value={accountData.auth ? t('authenticate') : t('not_authenticate')}
                                        InputProps={{
                                            readOnly: true,
                                            sx: {
                                                '& input': {
                                                    cursor: 'not-allowed'
                                                },
                                            },
                                        }}
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            textAlign: 'right',
                                            backgroundColor: 'rgba(0, 0, 0, 0.03)'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => navigate(`/accounts/${id}/auth`)}
                                        fullWidth
                                        sx={{
                                            height: '100%',
                                        }}
                                    >
                                        {t('authentication')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/')}
                            fullWidth
                            sx={{
                                width: '200px', height: '45px',
                                borderRadius: 2,
                                boxShadow: 2,
                                textTransform: 'none',
                                ':hover': {boxShadow: 4}
                            }}
                        >
                            {t('return_home')}
                        </Button>
                    </Grid>
                </Box>
            </Paper>
        </>
    );
};

export default ShowAccountPage;
