// src/App.js
import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import Sidebar from './components/Sidebar';
import ShowGroupPage from './pages/groups/ShowGroupPage';
import UpdateGroupPage from './pages/groups/UpdateGroupPage';
import IndexGroupsPage from './pages/groups/IndexGroupsPage';
import ShowMessagePage from './pages/messages/ShowMessagePage';
import ShowAccountPage from './pages/accounts/ShowAccountPage';
import StoreAccountPage from './pages/accounts/StoreAccountPage';
import UpdateMessagePage from './pages/messages/UpdateMessagePage';
import IndexAccountsPage from './pages/accounts/IndexAccountsPage';
import UpdateAccountPage from './pages/accounts/UpdateAccountPage';
import IndexContactsPage from './pages/contacts/IndexContactsPage';
import IndexMessagesPage from './pages/messages/IndexMessagesPage';
import StoreBatchGroupsPage from './pages/groups/StoreBatchGroupsPage';
import StoreGroupMessagePage from './pages/groups/StoreGroupMessagePage';
import IndexGroupMessagesPage from './pages/groups/IndexGroupMessagesPage';
import StoreAccountGroupPage from './pages/accounts/StoreAccountGroupPage';
import StoreBatchMessagesPage from './pages/messages/StoreBatchMessagesPage';
import IndexAccountGroupsPage from './pages/accounts/IndexAccountGroupsPage';
import AuthenticateAccountPage from './pages/accounts/AuthenticateAccountPage';
import IndexAccountMessagesPage from './pages/accounts/IndexAccountMessagesPage';
import StoreAccountBatchMessagesPage from "./pages/accounts/StoreAccountBatchMessagesPage";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1e88e5', // Nice blue color
        },
        secondary: {
            main: '#1976d2', // Slightly darker blue
        },
        background: {
            default: '#f5f5f5',
        }
    },
});

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Sidebar />
                    {/* Main content area */}
                    <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#ffffff', minHeight: '100vh' }}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/accounts" element={<IndexAccountsPage />} />
                            <Route path="/accounts/add" element={<StoreAccountPage />} />
                            <Route path="/accounts/:id" element={<ShowAccountPage />} />
                            <Route path="/accounts/:id/auth" element={<AuthenticateAccountPage />} />
                            <Route path="/accounts/:id/edit" element={<UpdateAccountPage />} />
                            <Route path="/accounts/:id/messages" element={<IndexAccountMessagesPage />} />
                            <Route path="/accounts/:id/messages/add/batch" element={<StoreAccountBatchMessagesPage />} />
                            <Route path="/accounts/:id/groups" element={<IndexAccountGroupsPage />} />
                            <Route path="/accounts/:id/groups/add" element={<StoreAccountGroupPage />} />
                            <Route path="/contacts" element={<IndexContactsPage />} />
                            <Route path="/groups" element={<IndexGroupsPage />} />
                            <Route path="/groups/add/batch" element={<StoreBatchGroupsPage />} />
                            <Route path="/groups/:id" element={<ShowGroupPage />} />
                            <Route path="/groups/:id/edit" element={<UpdateGroupPage />} />
                            <Route path="/groups/:id/messages" element={<IndexGroupMessagesPage />} />
                            <Route path="/groups/:id/messages/add" element={<StoreGroupMessagePage />} />
                            <Route path="/messages" element={<IndexMessagesPage />} />
                            <Route path="/messages/add/batch" element={<StoreBatchMessagesPage />} />
                            <Route path="/messages/:id" element={<ShowMessagePage />} />
                            <Route path="/messages/:id/edit" element={<UpdateMessagePage />} />
                        </Routes>
                    </Box>
                </Box>
            </Router>
        </ThemeProvider>
    );
};

export default App;
