import axios from 'axios';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper, Box, Grid, TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, MenuItem, Select, FormControlLabel, FormControl, Accordion, AccordionSummary, AccordionDetails, CircularProgress } from '@mui/material';
import {getApiUrl} from "../../utils/config";

const StoreAccountGroupPage = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        slow_mode: 0,
        chat_history_for_new_members: 'Visible',
        event_messages: 0,
        chat_reaction_setting: 'Disabled',
        accesses: ['ViewMembers'],
        contacts: {
            type: '',
            count: '',
            strategy: '',
        },
        message: {
            text: ''
        },
    });

    const [groupId, setGroupId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('fa');
    }, [i18n]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name.startsWith('message.')) {
            const key = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                message: {
                    ...prevData.message,
                    [key]: type === 'checkbox' ? checked : value,
                },
            }));
        }
        else if (name.startsWith('contacts.')) {
            const key = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                contacts: {
                    ...prevData.contacts,
                    [key]: type === 'checkbox' ? checked : value,
                },
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {

            const response = await axios.post(`${getApiUrl()}/api/groups`, {
                ...formData,
                account_id: id,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setGroupId(response?.data?.data?.id);

            setOpenDialog(true);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        navigate(`/groups/${groupId}/`);
    };

    return (
        <Paper sx={{ padding: 3, margin: 'auto', width: '100%' }}>
            <Box sx={{ direction: 'rtl' }}>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>{t('add_new_group')}</Typography>

                {/*{loading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />} /!* Loading indicator *!/*/}

                {loading && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Typography variant="subtitle1">{t('title')}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t('description')}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t('slow_mode')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="slow_mode"
                                    value={formData.slow_mode}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>{t('disabled')}</MenuItem>
                                    <MenuItem value={10}>{t('10s')}</MenuItem>
                                    <MenuItem value={30}>{t('30s')}</MenuItem>
                                    <MenuItem value={60}>{t('1m')}</MenuItem>
                                    <MenuItem value={300}>{t('5m')}</MenuItem>
                                    <MenuItem value={900}>{t('15m')}</MenuItem>
                                    <MenuItem value={3600}>{t('1h')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t('chat_history_for_new_members')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="chat_history_for_new_members"
                                    value={formData.chat_history_for_new_members}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Visible">{t('visible')}</MenuItem>
                                    <MenuItem value="Hidden">{t('hidden')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Event messages and chat reactions in one row */}
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t('event_messages')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="event_messages"
                                    value={formData.event_messages}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>{t('visible')}</MenuItem>
                                    <MenuItem value={0}>{t('hidden')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle1">{t('chat_reaction_setting')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="chat_reaction_setting"
                                    value={formData.chat_reaction_setting}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Disabled">{t('no_reaction')}</MenuItem>
                                    <MenuItem value="All">{t('all_reactions')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Accesses Accordion */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t('accesses')}</Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="accesses-content"
                                    id="accesses-header"
                                >
                                    <Typography>{t('select')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl component="fieldset">
                                        <Grid container>
                                            {['ViewMembers', 'ViewAdmins', 'SendMessages', 'AddMember'].map((access) => (
                                                <Grid item xs={12} key={access}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={formData.accesses.includes(access)}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    setFormData((prevData) => ({
                                                                        ...prevData,
                                                                        accesses: checked
                                                                            ? [...prevData.accesses, access]
                                                                            : prevData.accesses.filter((a) => a !== access),
                                                                    }));
                                                                }}
                                                                name={access}
                                                            />
                                                        }
                                                        label={t(access)}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 4 }}>
                            <Typography variant="subtitle1">{t('initial_contacts') + ' (' + t('optional') + ')'}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">{t('type')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="contacts.type"
                                    value={formData.contacts.type}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="automatic">{t('automatic')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">{t('count')}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="contacts.count"
                                type="number"
                                value={formData.contacts.count}
                                onChange={handleChange}
                                inputProps={{ min: 1, max: 1000 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="subtitle1">{t('strategy')}</Typography>
                            <FormControl fullWidth>
                                <Select
                                    name="contacts.strategy"
                                    value={formData.contacts.strategy}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="random">{t('random')}</MenuItem>
                                    <MenuItem value="least_messaging">{t('least_messaging')}</MenuItem>
                                    <MenuItem value="most_recent">{t('most_recent')}</MenuItem>
                                    <MenuItem value="least_recent">{t('least_recent')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 4 }}>
                            <Typography variant="subtitle1">{t('initial_message') + ' (' + t('optional') + ')'}</Typography>
                        </Grid>

                        {/* Nested message text field */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{t('text')}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="message.text"
                                value={formData.message.text}
                                onChange={handleChange}
                                multiline
                                rows={4}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                {t('submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            {error && <Typography color="error" sx={{ marginTop: 2 }}>{error}</Typography>}

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <Box sx={{ direction: 'rtl' }}>
                    <DialogTitle id="success-dialog-title">{t('operation_result')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="success-dialog-description">
                            {t('group_created_successfully')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                            {t('ok')}
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Paper>
    );
};

export default StoreAccountGroupPage;
